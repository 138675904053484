import Vue  from 'vue'
import Urls from '@/../config/url.js'
import moment from 'moment'
import { Message, Loading } from 'element-ui';
import router from '@/router'
import { testenv } from '@/../config/conf.js'

const module_name = 'catalogProduct'
const url = Urls.catalogProduct

const state = {
    list             : [],
    current          : {},
    data_loading     : false,
    total_count      : 0,
    edit_window      : false,
    options_list     : [],
    page_current     : 1,
    page_size        : 50,
    sort_field       : undefined,
    sort_direction   : undefined,
    filters          : {},
    product_types  : [
        {value: 'оборудование САМЕ',       label: 'оборудование САМЕ'},
        {value: 'оборудование BPT',        label: 'оборудование BPT'},
        {value: 'оборудование URBACO',     label: 'оборудование URBACO'},
        {value: 'оборудование OZAK',       label: 'оборудование OZAK'},
        {value: 'оборудование CAME Vector',       label: 'оборудование CAME Vector'},
        {value: 'сторонний производитель', label: 'сторонний производитель'},
    ],
    product_moderate_statuses  : [
        {value : 'moderate',       label: 'Отправлено на модерацию'},
        {value : 'moder_accept',   label: 'Отправлено админу'},
        {value : 'moder_decline',  label: 'Отклонено модератором'},
        {value : 'card',           label: 'Актуальные данные'},
    ],
    images_url: url + 'upload/',
    image_archive_window: false,
    changelog_window : false,
    changelog_1s_window : false,
    changelog_product: null,
    restlog_window : false,
    restlog_product: null,
    xml_export_window : false,
    xls_export_window : false,
    xlstech_export_window : false,
    complex_filter_data: false,
    complex_filter_filters: [],
    complex_filter_params: [],
    uploadtech_window: false,
    advfeedsettings_window: false,
    uploadxls_window: false,
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    get_paginated_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_paginated_list', props, { root: true })
    },

    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true }).then(() => {
            if (state.current && state.current.id)
                router.push('/catalog/products/' + state.current.id);
        })
    },

    get_complexfiltered_list({dispatch, commit, rootState}){
        let complex_filter_params = JSON.parse(JSON.stringify(state.complex_filter_params)).filter(x => x.usable)

        let props = {
            url                : url,
            name               : module_name,
            additional_filters : complex_filter_params
        }

        return dispatch('app/get_complexfiltered_list', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let minutes_old = 0
        if (localStorage.getItem('product_options_time')) {
            var now = moment(new Date());
            var saved = moment(localStorage.getItem('product_options_time'));
            var duration = moment.duration(now.diff(saved));
            minutes_old = duration.asMinutes()
        }

        let options_list = localStorage.getItem('product_options')
        if (options_list)
            options_list = JSON.parse(options_list);

        if (minutes_old >= 30 || !options_list || !options_list.length || options_list === 'undefined') {
            let props = {
                url          : url,
                name         : module_name,
                option_title : 'code',
            }

            return dispatch('app/get_options', props, { root: true }).then(()=>{
                localStorage.setItem('product_options', JSON.stringify(state.options_list))
                localStorage.setItem('product_options_time', moment(new Date()).toISOString())
            })
        }
        else {
            commit('set', {type: 'options_list', items: options_list})
            commit('set', {type: 'data_loading', items: false})
            return
        }
        
    },

    get_filterable_options({commit, dispatch, rootState}, data) {
        if ((!data || !('ids' in data) || !data.ids )&& !data.query)
            return [];
        if (!data.query && 'ids' in data && !data.ids.length)
            return [];
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'options/', data)
            .then((response) => {
                let options = []
                let body = response.data || []
                body.forEach(key => {
                    options.push({
                        label: key['code'],
                        value: key['id'],
                    });
                });
                resolve(options)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_product({dispatch, commit, rootState}, product_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + product_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_product_by_code({dispatch, commit, rootState}, product_code){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'code/' + product_code + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_product_full({dispatch, commit, rootState}, product_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'full/' + product_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_product_imagelink({dispatch, commit, rootState}, image_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'image/' + image_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_product_imageid({dispatch, commit, rootState}, image_name){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'imagename/' + image_name + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    send_to_moderation({dispatch, commit, rootState}, data){
        return Vue.axios.put(url + 'moderation/' + data.id + '/', data)
            .then((response) => {
                if (response.data.length){
                    if (response.data[0])
                        Message({
                            message: 'Данные товара отправлены на модерацию',
                            type: 'success'
                        });
                    if (response.data[1])
                        Message({
                            message: 'Данные товара сохранены в карточку',
                            type: 'success'
                        });
                }
                else {
                    Message({
                        message: 'Изменений не было',
                        type: 'success'
                    });
                }  
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },

    send_to_adminsave({dispatch, commit, rootState}, data){
        if ('funcs' in data.data) {
            data.data.funcs = data.data.funcs.filter(function (el) {
                return !el.del
            });
            data.data.funcs.forEach(item => {
                delete item["del"]
            })
        }
        if ('features' in data.data) {
            data.data.features = data.data.features.filter(function (el) {
                return !el.del
            });
            data.data.features.forEach(item => {
                delete item["del"]
            })
        }
        if ('parameters' in data.data) {
            data.data.parameters = data.data.parameters.filter(function (el) {
                return !el.del
            });
            data.data.parameters.forEach(item => {
                delete item["del"]
            })
        }
        if ('accessories' in data.data) {
            data.data.accessories = data.data.accessories.filter(function (el) {
                return !el.del
            });
            data.data.accessories.forEach(item => {
                delete item["del"]
            })
        }
        if ('accessory_for' in data.data) {
            data.data.accessory_for = data.data.accessory_for.filter(function (el) {
                return !el.del
            });
            data.data.accessory_for.forEach(item => {
                delete item["del"]
            })
        }
        if ('accessory_for_preset' in data.data) {
            data.data.accessory_for_preset = data.data.accessory_for_preset.filter(function (el) {
                return !el.del
            });
            data.data.accessory_for_preset.forEach(item => {
                delete item["del"]
            })
        }
        if ('spareparts' in data.data) {
            data.data.spareparts = data.data.spareparts.filter(function (el) {
                return !el.del
            });
            data.data.spareparts.forEach(item => {
                delete item["del"]
            })
        }
        if ('sparepart_for' in data.data) {
            data.data.sparepart_for = data.data.sparepart_for.filter(function (el) {
                return !el.del
            });
            data.data.sparepart_for.forEach(item => {
                delete item["del"]
            })
        }
        if ('sparepart_for_preset' in data.data) {
            data.data.sparepart_for_preset = data.data.sparepart_for_preset.filter(function (el) {
                return !el.del
            });
            data.data.sparepart_for_preset.forEach(item => {
                delete item["del"]
            })
        }
        if ('complect_content' in data.data) {
            data.data.complect_content = data.data.complect_content.filter(function (el) {
                return !el.del
            });
            data.data.complect_content.forEach(item => {
                delete item["del"]
            })
        }
        if ('complects_with_product' in data.data) {
            data.data.complects_with_product = data.data.complects_with_product.filter(function (el) {
                return !el.del
            });
            data.data.complects_with_product.forEach(item => {
                delete item["del"]
            })
        }
        if ('similar_products' in data.data) {
            data.data.similar_products = data.data.similar_products.filter(function (el) {
                return !el.del
            });
            data.data.similar_products.forEach(item => {
                delete item["del"]
            })
        }
        return Vue.axios.put(url + 'adminsave/' + data.id + '/', data)
            .then((response) => {
                if (response.data)
                    Message({
                        message: 'Данные товара сохранены в карточку',
                        type: 'success'
                    });
                else
                    Message({
                        message: 'Изменений не было',
                        type: 'success'
                    });
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },

    send_comment({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Urls.catalogProductComments + data.product_id + '/' + data.field + '/', {comment:data.comment})
            .then((response) => {
                resolve(response.data);
                Message({
                    message: 'Комментарий отправлен',
                    type: 'success'
                })
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_comments({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.catalogProductComments + data.product_id + '/' + data.field + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_parameters_values({dispatch, commit, rootState}, param_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'paramvalues/' + param_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject([]);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject([]);
        })
    },

    get_complect_content_price({dispatch, commit, rootState}, complect_content){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'complect-content-price/', complect_content)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_new_products({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'newlist/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_moderate_products({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'moderationlist/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_admin_products({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'adminlist/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_changed_products({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'changedlist/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_declined_products({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'declined-moderation-list/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_product_changelog({dispatch, commit, rootState}, id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'changelog/' + id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_product_changelog_1s({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'changelog-1s/' + data.id + '/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_image_archive({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'imagearchive/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_image_archive_status({dispatch, commit, rootState}, task_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'imagearchive/' + task_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    download_xml({dispatch, commit, rootState}, categories) {
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается XML файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.post(url + 'xml-download/', {categories:categories}).then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    },

    download_excel({dispatch, commit, rootState}, params) {
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается Excel файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.post(url + 'excel-download/', params).then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    },

    download_excel_tech({dispatch, commit, rootState}, params) {
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается Excel файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.post(url + 'excel-tech-download/', params).then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    },

    download_excel_nofeed({dispatch, commit, rootState}) {
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается Excel файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.post(url + 'excel-nofeed-download/').then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    },

    get_catalog_ierarchy({dispatch, commit, rootState}, params){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'ierarchy/', params)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    run_humanurl({ commit, rootState, dispatch }){
        let props = {
            url          : url + 'run-humanurl/',
        }
        return dispatch('app/run_action', props, { root: true })
    },
    get_humanurl_log({ commit, rootState, dispatch }){
        let props = {
            url          : url + 'get-humanurl-log/',
        }
        return dispatch('app/get_action_log', props, { root: true })
    },

    emulate_1c({dispatch, commit, rootState}, data){
        if (!testenv) return;
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'emulate-1c/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_advfeedsettings({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'advfeed-settings/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    save_advfeedsettings({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'advfeed-settings/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    run_fillview({dispatch, commit, rootState}, product_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'run-fillview/' + product_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_gpt_prompt({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'get-gpt-prompt/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_gpt_result({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'get-gpt-result/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
