<template>
    <div>
        <div v-if="is_authed" id="app-container" v-loading="app_loading">
            <div id="app-sidebar" v-if="sidebar"><Sidebar /></div>
            <div id="app-holder" v-bind:class="{ sidebared: sidebar }">
                <div id="app-header"><Navbar /></div>
                <div id="app-body"><router-view :key="$route.fullPath" /></div>
            </div>
        </div>
        <div v-else-if="$route.name === 'auth_by_hash'"><router-view :key="$route.fullPath" /></div>
        <div v-else><Login /></div>
    </div>
</template>

<script>
import Login from './App/Login.vue'
import Sidebar from './App/Sidebar'
import Navbar from './App/Navbar.vue'
import { mapState } from 'vuex'
import { routerComponents } from '@/router/'

export default {
    components: {
        Login,
        Sidebar,
        Navbar,
    },

    computed: {
        ...mapState({
            is_authed   : state => state.app.is_authed,
            sidebar     : state => state.app.sidebar,
            app_loading : state => state.app.app_loading,
            user        : state => state.app.user,
            routes      : state => state.app.routes,
            mobile      : state => state.app.mobile,
        }),
    },

    methods: {
        fill_router(){
            if (!this.routes)
                return;
            this.routes.forEach(r => {
                const comp_data = routerComponents.find(x => x.name === r.module)
                if (!comp_data && !r.chapter && r.children && r.children.length){
                    this.$router.addRoute({ 
                        path: r.path, 
                        name: r.module, 
                        redirect: r.children[0].path || '/',
                        hidden: r.hidden || false,
                    })
                }
                else if (!comp_data && !r.chapter && !r.url){
                    console.log(r)
                }
                else if(comp_data && (!r.chapter || r.url)) {
                    this.$router.addRoute({ 
                        path: r.path, 
                        name: r.module, 
                        component: comp_data.component,
                        meta: {
                            title: r.title,
                            roles: r.view_roles,
                            hidden: r.hidden || false,
                            testonly: r.testonly || false,
                            mobile: r.comp_data || false,
                        }
                    })
                }
            })
        },
    },

    created: function() {
        this.$store.dispatch('app/check_auth').then(() => {
            this.fill_router()
        })
        if (window.innerWidth < 768)
            this.$store.commit('app/set', {type: 'mobile', items:true});
        else
            this.$store.commit('app/set', {type: 'mobile', items:false});
    },

    watch:{
        $route (to, from){
            return;
            if (!to.meta || !to.meta.roles){
                return
            }

            if (!this.user.role) {
                return
            }
            let allowed = to.meta.roles.filter(i => this.user.role.includes(i))
            if (!allowed.length)
                this.$router.push('/')
        },

        user (to, from){
            this.fill_router()
            
            if (!this.user.role) {
                return
            }
            else if (!this.$route.meta) {
                return
            }
            else if (!this.$route.meta.roles) {
                return
            }
            else {
                let allowed = this.$route.meta.roles.filter(i => this.user.role.includes(i))
                if (!allowed.length)
                    this.$router.push('/');
            }

        },
    }
}
</script>
