import Vue  from 'vue'
import Urls from '@/../config/url.js'

const module_name = 'catalogWarehouse'
const url = Urls.catalogWarehouse

const state = {
    list           : [],
    current        : {},
    data_loading   : false,
    total_count    : 0,
    edit_window    : false,
    options_list   : [],
}

var preprocessItem = function(item) {
    let extra = ['excel_file', 'excel_date']
    extra.forEach(f => {
        delete item[f]
    })
    return item
}

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'name',
            add_fields   : ['not_show'],
        }

        return dispatch('app/get_options', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    run_excelfiles({ commit, rootState, dispatch }){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'run-excelfiles/')
            .then((response) => {
                let body = response.data
                if (body.status && body.status === 'start') {
                    resolve(true);
                }
                else {
                    Message({
                        message: 'Процесс уже запущен на сервере. Пожалуйста, дождитесь окончания.',
                        type: 'error'
                    })
                    resolve(false);
                }
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_excelfiles_log({commit, dispatch}){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'get-excelfiles-log/')
            .then((response) => {
                let body = response.data
                let sip = body.stop ? false : true
                resolve({ run_in_progress: sip, content: body.content })
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
