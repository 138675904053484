<template>
    <div>
        <div id="app-sidebar--close">
            <div  @click="toggle_sidebar">
                <svg-icon icon-class="times" />
            </div>
        </div>
        <el-menu
            :default-active="$route.path"
            mode="vertical"
            class="menu-nav-menu"
            background-color="#ffffff"
            text-color="#222222"
            active-text-color="#00b0ed"
            unique-opened
            router
            @select="selectItem">

            <template v-for="route in routes_tree">
                <el-submenu :index="route.path" v-if="route.children && route.children.length && can_show(route)">
                    <template slot="title">
                        <span>{{ route.title }}</span>
                    </template>

                    <template v-for="subroute in route.children">
                        <el-submenu :index="subroute.path" v-if="subroute.children && subroute.children.length  && can_show(subroute)">
                            <template slot="title">
                                <span>{{ subroute.title }}</span>
                            </template>
                            <template v-for="subsubroute in subroute.children">
                                <el-submenu :index="subsubroute.path" v-if="subsubroute.children && subsubroute.children.length  && can_show(subsubroute)">
                                    <template slot="title">
                                        <span>{{ subsubroute.title }}</span>
                                    </template>
                                </el-submenu>

                                <el-menu-item :index="subsubroute.path" v-else-if="can_show(subsubroute)">
                                    <a :href="subsubroute.url" v-if="subsubroute.url" target="_blank">{{subsubroute.title}}</a>
                                    <div v-else @contextmenu.prevent="$refs.menu.open($event, { routepath: subsubroute.path })">{{subsubroute.title}}</div>
                                </el-menu-item>
                            </template>
                        </el-submenu>

                        <el-menu-item :index="subroute.path" v-else-if="can_show(subroute)">
                            <a :href="subroute.url" v-if="subroute.url" target="_blank">{{subroute.title}}</a>
                            <div v-else @contextmenu.prevent="$refs.menu.open($event, { routepath: subroute.path })">{{subroute.title}}</div>
                        </el-menu-item>
                    </template>
                </el-submenu>

                <el-menu-item :index="route.path" v-else-if="can_show(route)">
                    <a :href="route.url" v-if="route.url" target="_blank">{{route.title}}</a>
                    <div v-else @contextmenu.prevent="$refs.menu.open($event, { routepath: route.path })">{{route.title}}</div>
                </el-menu-item>
            </template>

            <vue-context ref="menu">
                <template slot-scope="child">
                    <li>
                        <a href="#" @click.prevent="on_context_click($event.target, child.data)">открыть в новом окне</a>
                    </li>
                </template>
            </vue-context>
        </el-menu>
    </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'    
import VueContext from 'vue-context';
import XEUtils from 'xe-utils'
import { testenv } from '@/../config/conf.js'

export default {
    components: {
        VueContext
    },
    computed: {
        ...mapState({
            user   : state => state.app.user,
            routes : state => state.app.routes,
            mobile : state => state.app.mobile,
        }),

        routes_tree(){
            const tree = XEUtils.toArrayTree(this.routes, { key: 'id', parentKey: 'parent', sortKey: 'sortn', reverse: false });
            return tree
        }
    },
    methods: {
        on_context_click (event, data) {
            if (data && data.routepath) {
                let route = this.$router.resolve({path: data.routepath});
                window.open(route.href, '_blank');
            }
        },

        can_show(item){
            let can_show = true
            if (item.hidden)
                can_show = false;
            else if (item.testonly && !testenv)
                can_show = false;
            else if (this.mobile && !item.mobile)
                can_show = false;

            if (!can_show && item.chapter && item.children && item.children.length){
                item.children.forEach(child => {
                    const can_show_child = this.can_show(child)
                    if (can_show_child)
                        can_show = true;
                })
            }

            return can_show;
        },

        toggle_sidebar(){
            this.$store.dispatch('app/toggle_sidebar')
        },

        selectItem(index, indexPath){
            if (this.mobile)
                this.$store.dispatch('app/toggle_sidebar');
        }
    }
}
</script>
