import Vue  from 'vue'
import Urls from '@/../config/url.js'

const module_name = 'dashboard'
const url = Urls.dashboard

const actions = {
    last_comments_widget({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'last-comments-widget/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    last_user_comments_widget({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'last-usercomments-widget/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    ecomm_list_widget({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'ecomm-list-widget/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    dealers_moderation_widget({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'dealers-moderation-widget/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    dealers_new_widget({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'dealers-new-widget/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

export default {
  namespaced: true,
  actions,
}
