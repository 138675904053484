import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueJwtDecode from 'vue-jwt-decode'
import Cookies from 'js-cookie'
import { Message, Loading } from 'element-ui';
import Conf from '@/../config/url.js'
import router from '@/router'
import { testenv } from '@/../config/conf.js'
import moment from 'moment-timezone'
import XEUtils from 'xe-utils'
import { routerComponents } from '@/router/'

// import {version} from '@/../package.json';
const appVersion = require('@/../package.json').version;

function getAuthToken () {
    let accessToken  = localStorage.came_access;
    let refreshToken = localStorage.came_refresh;

    // if (process.env.NODE_ENV === 'development') {
    //     console.log({accessToken: accessToken, refreshToken: refreshToken, exp: VueJwtDecode.decode(accessToken).exp, now: (Date.now()  / 1000).toFixed(0), expired: VueJwtDecode.decode(accessToken).exp <= (Date.now()  / 1000).toFixed(0)})
    // }
    
    if (accessToken && accessToken !== 'undefined' && refreshToken && refreshToken !== 'undefined' && VueJwtDecode.decode(accessToken).exp <= (Date.now()  / 1000).toFixed(0)) {
        if (process.env.NODE_ENV === 'development') console.log('token should be updated')
        let authTokenRequest = axios.post(Conf.refreshToken, {}, {
                headers: {
                    'Authorization': 'Bearer ' + refreshToken
                }
            })
            .then(response => {
                localStorage.came_access = response.data.access_token;
                return response.data.access_token
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') console.log(error.request);
                localStorage.removeItem('came_access');
                localStorage.removeItem('came_refresh');
            })
        return authTokenRequest
    }
    return accessToken
}

axios.defaults.timeout = 180000;

axios.interceptors.request.use(async function (config) {
    if (config.url !== Conf.login && config.url !== Conf.refreshToken) {
        let token = await getAuthToken()
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (config) {
    return config
}, function (error) {
    if (error.request !== undefined && (error.request.responseURL === Conf.refreshToken || error.request.status === 401 && error.config.__isRetryRequest)) {
        localStorage.removeItem('came_access');
        localStorage.removeItem('came_refresh');
        Message.error('Authentication Error')
        return Promise.reject(error)
    }
    else if (error.request !== undefined && error.request.status === 401) {
        error.config.__isRetryRequest = true
        return axios.request(error.config)
    }

    return Promise.reject(error)
})

Vue.use(VueAxios, axios)

const item_extra_fields = ['_X_ID','id','_X_CHILD', '_X_ROW_KEY', '_X_ROW_CHILD'];

const state = {
    is_authed         : false,
    app_loading       : false,
    user              : {},
    package_version   : appVersion,
    routes            : [],
    sidebar           : !+Cookies.get('appsidebar'),
    seo_changefreq: [
        {value: 'always',  label: 'always'},
        {value: 'hourly',  label: 'hourly'},
        {value: 'daily',   label: 'daily'},
        {value: 'weekly',  label: 'weekly'},
        {value: 'monthly', label: 'monthly'},
        {value: 'yearly',  label: 'yearly'},
        {value: 'never',   label: 'never'},
    ],
    timezones: [],
    mobile: false,
}

const getters = {
    userid: state => {
        return state.user.id
    },
    admin: state => {
        return (state.user.role && state.user.role.includes('admin')) ? true : false
    },
    editor: state => {
        return (state.user.role && state.user.role.includes('pim_editor')) ? true : false
    },
    moderator: state => {
        return (state.user.role && state.user.role.includes('pim_moderator')) ? true : false
    },
    prog1c: state => {
        return (state.user.role && state.user.role.includes('1c')) ? true : false
    },
    marketolog: state => {
        return (state.user.role && state.user.role.includes('marketolog')) ? true : false
    },
    tech_expert: state => {
        return (state.user.role && state.user.role.includes('tech_expert')) ? true : false
    },
    director_op: state => {
        return (state.user.role && state.user.role.includes('director_op')) ? true : false
    },
    developer: state => {
        return (state.user.role && state.user.role.includes('developer')) ? true : false
    },
    smm: state => {
        return (state.user.role && state.user.role.includes('smm')) ? true : false
    },
    academy: state => {
        return (state.user.role && state.user.role.includes('academy')) ? true : false
    },
}


const actions = {
    login({commit, dispatch}, data){
        commit('set', {type: 'is_authed', items: false});

        Vue.axios.post(Conf.login, data).then(response => {
            let body = response.data
            if ('access_token' in body && body.access_token) {
                localStorage.came_access = body.access_token;
                localStorage.came_refresh = body.refresh_token;
                commit('set', {type: 'is_authed', items: true});
                dispatch('get_current_user')
                router.push({ path: '/' }).catch(()=>{});
            }
            else {
                Message.error('Authentication Error')
            }
        })
        .catch(error => {
            dispatch('process_error', error)
        })
    },

    get_current_user({commit, dispatch}){
        // commit('set', {type: 'user', items: {}});

        return Vue.axios.post(Conf.checkToken).then((response) => {
            let body = response.data
            body._id = body.id
            commit('set', {type: 'user', items:body})
            dispatch('generate_routes')
        })
        .catch(error => {
            commit('set', {type: 'user', items: {}});
            dispatch('process_error', error)
        })
    },

    process_error({commit}, error){
        if (process.env.NODE_ENV === 'development') {
            if (error.request) console.log(error.request);
            else console.log(error);
        }
        if (error.request && error.request.status) {
            if (error.request.status == 401) {
                localStorage.removeItem('came_access');
                localStorage.removeItem('came_refresh');
                commit('set', {type: 'is_authed', items: false});
            }
            else if (error.request.status == 422) {
                let msg = JSON.parse(error.request.responseText)
                if (msg.msg === 'Not enough segments' || msg.message === 'Not enough segments') {
                    localStorage.removeItem('came_access');
                    localStorage.removeItem('came_refresh');
                    commit('set', {type: 'is_authed', items: false});
                }
                else if (error.request.responseText) {
                    let msg = JSON.parse(error.request.responseText)
                    Message({
                        message: error.request.status + ' ' + msg.message,
                        type: 'error',
                        dangerouslyUseHTMLString: true,
                    })
                }
            }
            else if (error.request.responseText) {
                let msg = JSON.parse(error.request.responseText)
                Message({
                    message: msg.message,
                    type: 'error',
                    dangerouslyUseHTMLString: true,
                })
            }
        }
        else {
            Message({
                message: 'Серверная ошибка',
                type: 'error'
            })
        }
    },

    check_auth({commit, dispatch}){
        let has_token = (localStorage.came_access) ? true : false;
        commit('set', {type: 'is_authed', items: has_token});
        if (has_token) dispatch('get_current_user')
    },

    generate_routes({commit}){
        var role = state.user.role
        let routes = []
        state.user.menu.forEach(r => {
            const comp_data = routerComponents.find(x => x.name === r.module)
            if (comp_data){
                if (!r.testonly && comp_data.testonly)
                    r.testonly = comp_data.testonly;
                if (comp_data.mobile)
                    r.mobile = comp_data.mobile
            }
            routes.push(r)
        })
        commit('set', {type: 'routes', items: routes });
    },

    toggle_sidebar({commit}) {
        if (state.sidebar) {
            Cookies.set('appsidebar', 1)
        } else {
            Cookies.set('appsidebar', 0)
        }
        state.sidebar = !state.sidebar
    },

    logout({commit}) {
        localStorage.removeItem('came_access');
        localStorage.removeItem('came_refresh');
        commit('set', {type: 'is_authed', items: false});
    },

    get_list({commit, dispatch, rootState, rootGetters}, props){
        commit(props.name + '/set', {type: 'list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })
        commit(props.name + '/set', {type: 'current', items:true}, { root: {} })

        let query_params = {}
        if (props.query_params)
            query_params['where'] = JSON.stringify(props.query_params)

        return Vue.axios.get(props.url, {params: query_params}).then((response) => {
            let body = response.data
            let list = ('postprocess' in props) ? props.postprocess(body, rootGetters) : body
            commit(props.name + '/set', {type: 'list', items:list}, { root: true })
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
            commit(props.name + '/set', {type: 'total_count', items:list.length}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    get_paginated_list({commit, dispatch, rootState, rootGetters}, props){
        commit(props.name + '/set', {type: 'list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })
        commit(props.name + '/set', {type: 'current', items:true}, { root: {} })

        let params = {
            page  : rootState[props.name].page_current,
            limit : rootState[props.name].page_size,
            find  : rootState[props.name].filters,
        }
        if (rootState[props.name].sort_field) {
            let sort_field = rootState[props.name].sort_field
            if (rootState[props.name].sort_direction === 'desc') 
                sort_field = '-' + sort_field
            params.sort = sort_field
        }

        let query_params = {
            where: JSON.stringify(params)
        }
        
        return Vue.axios.get(props.url, {params: query_params}).then((response) => {
            let body = response.data
            let list = ('postprocess' in props) ? props.postprocess(body.items) : body.items
            commit(props.name + '/set', {type: 'list', items:list}, { root: true })
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
            commit(props.name + '/set', {type: 'total_count', items:body.total}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    get_options({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'options_list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })

        let query_params = {}
        if (props.query_params)
            query_params['where'] = JSON.stringify(props.query_params)

        return Vue.axios.get(props.url + 'options/', {params: query_params}).then((response) => {
            let body = response.data
            let options = []
            body.forEach(key => {
                let label = ''
                if (typeof props.option_title === 'object') {
                    let label_array = []
                    props.option_title.forEach(k => {
                        label_array.push(key[k])
                    })
                    label = label_array.join(' ')
                }
                else {
                    label = key[props.option_title]
                }
                let option = {
                    label: label,
                    value: key[props.option_value || 'id'],
                }
                if (props.add_field)
                    option[props.add_field] = key[props.add_field]
                if (props.add_fields) {
                    props.add_fields.forEach(f => {
                        option[f] = key[f]
                    })
                }
                options.push(option);
            });

            commit(props.name + '/set', {type: 'options_list', items:options}, { root: true })
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    get_complexfiltered_list({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })
        commit(props.name + '/set', {type: 'current', items:true}, { root: {} })

        let params = {
            page    : rootState[props.name].page_current,
            limit   : rootState[props.name].page_size,
            filters : rootState[props.name].complex_filter_filters
        }

        if (props.additional_filters)
            params.additional_filters = props.additional_filters;

        return Vue.axios.post(props.url + 'complexfilter/', params).then(response => {
            let body = response.data
            commit(props.name + '/set', {type: 'list', items:body.items}, { root: true })
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
            commit(props.name + '/set', {type: 'total_count', items:body.total}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    add_item({commit, dispatch, rootState, rootGetters}, props){
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })
        let st = rootState[props.name];

        let data = JSON.parse(JSON.stringify(props.item, (k, v) => v === undefined ? null : v));
        item_extra_fields.forEach(key => {
            delete data[key]
        });

        data = ('preprocess' in props) ? props.preprocess(data) : data;

        return Vue.axios.post(props.url, data).then(response => {
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })

            let item = ('postprocess' in props) ? props.postprocess(response.data, rootGetters) : response.data;
            let array = st.list
            array.unshift(item)

            commit(props.name + '/set', {type: 'list', items:array}, { root: true })
            commit(props.name + '/set', {type: 'current', items:item}, { root: true })

            let total_count = st.total_count + 1
            commit(props.name + '/set', {type: 'total_count', items:total_count}, { root: true })

            if (!props.noMessage) Message({
                message: 'Добавлено успешно',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    update_item({commit, dispatch, rootState, rootGetters}, props){
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })
        let st = rootState[props.name];

        let data = JSON.parse(JSON.stringify(props.item, (k, v) => v === undefined ? null : v));
        item_extra_fields.forEach(key => {
            delete data[key]
        });

        data = ('preprocess' in props) ? props.preprocess(data) : data;
        console.log(data)

        return Vue.axios.put(props.url + props.item.id + '/', data).then(response => {
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })

            let item = ('postprocess' in props) ? props.postprocess(response.data, rootGetters) : response.data;
            let array = st.list
            let index = array.findIndex(x => x.id === item.id);
            Vue.set(array, index, item);

            commit(props.name + '/set', {type: 'list', items:array}, { root: true })
            commit(props.name + '/set', {type: 'current', items:item}, { root: true })

            if (!props.noMessage) Message({
                message: 'Отредактировано успешно',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    delete_item({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })
        let st = rootState[props.name];

        return Vue.axios.delete(props.url + props.data + '/').then(response => {
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })

            let array = st.list
            let index = array.findIndex(x => x.id === props.data);
            array.splice(index, 1)

            commit(props.name + '/set', {type: 'list', items:array}, { root: true })
            commit(props.name + '/set', {type: 'current', items:{}}, { root: true })

            let total_count = st.total_count - 1
            commit(props.name + '/set', {type: 'total_count', items:total_count}, { root: true })

            if (!props.noMessage) Message({
                message: 'Удалено успешно',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    get_images_list({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'images_list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'images_loading', items:true}, { root: true })

        return Vue.axios.get(props.url).then((response) => {
            let body = response.data
            let list = ('process' in props) ? props.process(body) : body
            commit(props.name + '/set', {type: 'images_list', items:list}, { root: true })
            commit(props.name + '/set', {type: 'images_loading', items:false}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'images_loading', items:false}, { root: true })
        })
    },

    delete_image({commit, dispatch, rootState}, props) {
        let url = props.url + props.data.record_id + '/' + props.data.image_id + '/'
        commit(props.name + '/set', {type: 'images_loading', items:true}, { root: true })

        return Vue.axios.delete(url).then(response => {
            if (!props.noMessage) 
                Message({
                    message: 'Изображение удалено',
                    type: 'success'
                });
            return dispatch(props.name + '/get_images_list', props.data.record_id, { root: true })
        })
    },

    update_image({commit, dispatch, rootState}, props) {
        let url = props.url + props.item.record_id + '/' + props.item.id + '/'
        let data = JSON.parse(JSON.stringify(props.item));
        item_extra_fields.forEach(key => {
            delete data[key]
        });
        
        let st = rootState[props.name];
        return Vue.axios.put(url, data).then(response => {
            commit(props.name + '/set', {type: 'images_loading', items:false}, { root: true })

            let item = response.data
            let array = st.images_list
            let index = array.findIndex(x => x.id === item.id);
            Vue.set(array, index, item);

            commit(props.name + '/set', {type: 'images_list', items:array}, { root: true })

            if (!props.noMessage) Message({
                message: 'Изображение отредактировано',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'images_loading', items:false}, { root: true })
        })
    },

    get_video_list({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'video_list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'video_loading', items:true}, { root: true })

        return Vue.axios.get(props.url).then((response) => {
            let body = response.data
            let list = ('process' in props) ? props.process(body) : body
            commit(props.name + '/set', {type: 'video_list', items:list}, { root: true })
            commit(props.name + '/set', {type: 'video_loading', items:false}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'video_loading', items:false}, { root: true })
        })
    },

    delete_video({commit, dispatch, rootState}, props) {
        let url = props.url + props.data.record_id + '/' + props.data.video_id + '/'
        commit(props.name + '/set', {type: 'video_loading', items:true}, { root: true })

        return Vue.axios.delete(url).then(response => {
            if (!props.noMessage) 
                Message({
                    message: 'Видео удалено',
                    type: 'success'
                });
            return dispatch(props.name + '/get_video_list', props.data.record_id, { root: true })
        })
    },

    update_video({commit, dispatch, rootState}, props) {
        let url = props.url + props.item.record_id + '/' + props.item.id + '/'
        let data = JSON.parse(JSON.stringify(props.item));
        item_extra_fields.forEach(key => {
            delete data[key]
        });
        
        let st = rootState[props.name];
        return Vue.axios.put(url, data).then(response => {
            commit(props.name + '/set', {type: 'video_loading', items:false}, { root: true })

            let item = response.data
            let array = st.video_list
            let index = array.findIndex(x => x.id === item.id);
            Vue.set(array, index, item);

            commit(props.name + '/set', {type: 'video_list', items:array}, { root: true })

            if (!props.noMessage) Message({
                message: 'Видео отредактировано',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'video_loading', items:false}, { root: true })
        })
    },

    get_files_list({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'files_list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'files_loading', items:true}, { root: true })

        return Vue.axios.get(props.url).then((response) => {
            let body = response.data
            let list = ('process' in props) ? props.process(body) : body
            commit(props.name + '/set', {type: 'files_list', items:list}, { root: true })
            commit(props.name + '/set', {type: 'files_loading', items:false}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'files_loading', items:false}, { root: true })
        })
    },

    delete_files({commit, dispatch, rootState}, props) {
        let url = props.url + props.data.record_id + '/' + props.data.files_id + '/'
        commit(props.name + '/set', {type: 'files_loading', items:true}, { root: true })

        return Vue.axios.delete(url).then(response => {
            if (!props.noMessage) 
                Message({
                    message: 'Файл удален',
                    type: 'success'
                });
            return dispatch(props.name + '/get_files_list', props.data.record_id, { root: true })
        })
    },

    update_files({commit, dispatch, rootState}, props) {
        let url = props.url + props.item.record_id + '/' + props.item.id + '/'
        let data = JSON.parse(JSON.stringify(props.item));
        item_extra_fields.forEach(key => {
            delete data[key]
        });
        
        let st = rootState[props.name];
        return Vue.axios.put(url, data).then(response => {
            commit(props.name + '/set', {type: 'files_loading', items:false}, { root: true })

            let item = response.data
            let array = st.files_list
            let index = array.findIndex(x => x.id === item.id);
            Vue.set(array, index, item);

            commit(props.name + '/set', {type: 'files_list', items:array}, { root: true })

            if (!props.noMessage) Message({
                message: 'Файл отредактирован',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'files_loading', items:false}, { root: true })
        })
    },

    generate_seo_records({commit, dispatch, rootState}, props) {
        commit(props.name + '/set', {type: 'list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'data_loading', items:true}, { root: true })

        return Vue.axios.post(props.url + 'generate/').then((response) => {
            Message({
                message: 'SEO записи заполнены по шаблонам',
                type: 'success'
            })
            dispatch('get_list', props)
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'data_loading', items:false}, { root: true })
        })
    },

    download_excel({commit, dispatch, rootState}, props){
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается Excel файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.get(props.url + 'excel-download/').then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('process_error', error)
        })
    },

    run_action({commit, dispatch, rootState}, props){
        return new Promise((resolve, reject) => {
            Vue.axios.post(props.url)
            .then((response) => {
                let body = response.data
                if (body.status && body.status === 'start') {
                    resolve(true);
                }
                else {
                    Message({
                        message: 'Процесс уже запущен на сервере. Пожалуйста, дождитесь окончания.',
                        type: 'error'
                    })
                    resolve(false);
                }
            })
            .catch(error => {
                dispatch('process_error', error)
                reject(error);
            })
        }, error => {
            dispatch('process_error', error)
            reject(error);
        })
    },
    
    get_action_log({commit, dispatch, rootState}, props){
        return new Promise((resolve, reject) => {
            Vue.axios.post(props.url)
            .then((response) => {
                let body = response.data
                let sip = body.stop ? false : true
                resolve({ run_in_progress: sip, content: body.content })
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    restore_password_request({dispatch, commit, rootState}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Conf.restorePasswordRequest, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_timezone_options({commit}){
        const ruZones = moment.tz.zonesForCountry('RU', true)
        const kzZones = moment.tz.zonesForCountry('KZ', true)
        const byZones = moment.tz.zonesForCountry('BY', true)
        
        const timezones = [...ruZones, ...kzZones, ...byZones]
        .sort((a, b) => {
            return b.offset - a.offset
        })

        let options = []
        timezones.forEach(tz => {
            const utcoffset = moment.tz(tz.name).format('ZZ');
            options.push({
                "label": moment.tz(tz.name).format('Z'),
                "value": utcoffset
            })
        })

        const values = options.map(({ value }) => value);
        const filtered = options.filter(({ value }, index) =>
            !values.includes(value, index + 1));
        commit('set', {type: 'timezones', items:filtered})
    },

    run_resize_images({ commit, rootState, dispatch }, entity){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Conf.adminFileManager + 'run-resize/', {entity: entity})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_resize_log({commit, dispatch}){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Conf.adminFileManager + 'get-resize-log/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    check_uniq_url({commit, dispatch}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Conf[data.module] + 'check-uniq-url/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    }
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
